// @ts-strict-ignore
// @team @facilitators
import React, { Component } from "react"
import classNames from "classnames"
import TextInput from "components/TextInput"
import { DATE_MASK } from "components/MaskedInput"
import Icon from "components/Icon"
import { DATE_FORMAT, format, isDateValid } from "utilities/date"
import { IS_DATE_INPUT_SUPPORTED } from "utilities/detection"
import isTouchDevice from "is-touch-device"
import Flatpickr from "react-flatpickr"
import { english } from "flatpickr/dist/l10n/default.js"
import { isTabOrEnter } from "utilities/browser/event"
import * as styles from "./index.module.scss"
import { useFeatureFlags } from "components/FeatureFlagContext"
import deduceDate from "utilities/nlpDate"
import { normalizeDate } from "./utilities"
import { DatePicker } from "./DatePicker"

export type DatePickerWidth = "sm" | "auto"

export const DATEPICKER_DATE_FORMAT = "m/d/Y"
export const WEEKDAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

export function onPaste(event: React.ChangeEvent<HTMLInputElement>) {
  const { value } = event.target
  const date = deduceDate(value)
  event.target.value = date ? format(date) : normalizeDate(value)
}

export function formatDate(date) {
  if (!date) {
    return ""
  }
  if (!isDateValid(date)) {
    return ""
  }
  return format(date)
}

export function formatForDateInput(date) {
  if (!date) {
    return
  }
  return format(date, "YYYY-MM-DD")
}

export type DatePickerProps = {
  name?: string
  id?: string
  value?: string
  disabled?: boolean
  className?: string
  onChange?: React.FormEventHandler
  minDate?: string
  maxDate?: string
  disabledDates?: string[]
  hasDateInput?: boolean
  autoFocus?: boolean
  handleNavigationKey?(): void
  width?: DatePickerWidth
  inline?: boolean
}

class LegacyDatePicker extends Component<DatePickerProps> {
  datepicker: { toggle(): void }

  static defaultProps = {
    hasDateInput: IS_DATE_INPUT_SUPPORTED && isTouchDevice(),
    disabledDates: [],
    width: "auto",
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.value === "" ||
      !!isDateValid(nextProps.value) ||
      nextProps.disabled !== this.props.disabled
    )
  }

  render() {
    let hiddenInput = null
    const {
      name,
      id,
      value,
      disabled,
      className,
      onChange,
      hasDateInput,
      minDate,
      maxDate,
      disabledDates,
      autoFocus,
      handleNavigationKey,
      width,
      inline,
    } = this.props

    const inputProps = {
      name,
      id,
      disabled,
      className: classNames(
        "form-control",
        { [styles.formControlSm]: width === "sm" },
        className
      ),
      onChange,
    }
    const onDateInputChange = (event) => {
      const date = event.target.value
      hiddenInput.value = formatDate(date)
      hiddenInput.dispatchEvent(new Event("blur", { bubbles: true }))
      hiddenInput.dispatchEvent(new Event("change", { bubbles: true }))
      if (date && typeof onChange === "function") {
        onChange(({
          target: { value: format(date), name, id },
        } as unknown) as React.FormEvent)
      }
    }

    return (
      <div
        className={classNames(styles.container, inline && styles.inline, {
          "input-group": !hasDateInput,
          [styles.inputGroupSm]: width === "sm",
        })}
      >
        {hasDateInput ? (
          <>
            <input
              type="date"
              disabled={disabled}
              autoFocus={autoFocus}
              className={classNames("form-control", className)}
              min={formatForDateInput(minDate)}
              max={formatForDateInput(maxDate)}
              value={formatForDateInput(value)}
              onChange={onDateInputChange}
              aria-disabled={disabled}
            />
            <input
              type="hidden"
              ref={(node) => (hiddenInput = node)}
              readOnly
              value={formatDate(value)}
              id={id}
              name={name}
            />
          </>
        ) : (
          <Flatpickr
            value={value}
            onCreate={(flatpickr) => {
              this.datepicker = flatpickr
            }}
            onDestroy={() => delete this.datepicker}
            onClose={() => {
              /*Note: It was necessary to include an onClose function here because without one, the library automatically blurs on close and overrides our custom focusing.
                                                  https://github.com/haoxins/react-flatpickr/blob/3a581d2b2e9f358e388ec7ff8596ba1a0442a99d/lib/index.js#L98-L104*/
            }}
            options={{
              inline,
              minDate,
              maxDate,
              disable: disabledDates.map(formatForDateInput),
              onChange: (dates) => {
                const date = dates[0]
                if (date && typeof onChange === "function") {
                  onChange(({
                    target: { value: format(date), name, id },
                  } as unknown) as React.FormEvent)
                }
              },
              allowInput: true,
              clickOpens: false,
              dateFormat: DATEPICKER_DATE_FORMAT,
              locale: {
                ...english,
                weekdays: {
                  shorthand: WEEKDAYS,
                },
              },
            }}
            render={(props, ref) => {
              return (
                <>
                  <TextInput
                    {...inputProps}
                    autoComplete="off"
                    value={value}
                    mask={DATE_MASK}
                    onPaste={onPaste}
                    autoFocus={autoFocus}
                    placeholder={DATE_FORMAT}
                    ref={ref}
                    onKeyDown={(event) => {
                      isTabOrEnter(event) &&
                        handleNavigationKey &&
                        handleNavigationKey()
                    }}
                  />
                  {!inline && (
                    <span
                      className={classNames(
                        "datepicker input-group-append input-group-text",
                        styles.append,
                        {
                          pointer: !hasDateInput,
                        }
                      )}
                      onClick={() => this.datepicker.toggle()}
                    >
                      <Icon prefix="far" type="calendar-alt" />
                    </span>
                  )}
                </>
              )
            }}
          />
        )}
      </div>
    )
  }
}

const DatePickerComponent = (props) => {
  const featureFlags = useFeatureFlags()

  const Component = featureFlags.isFeatureEnabled(
    "useFunctionalDatePickerComponent"
  )
    ? DatePicker
    : LegacyDatePicker
  return <Component {...props} />
}

export default DatePickerComponent
